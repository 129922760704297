var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  iframe {\n    height: 100vh;\n    border: 0;\n  }\n"], ["\n  iframe {\n    height: 100vh;\n    border: 0;\n  }\n"])));
var PaymentBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: #007f49;\n  padding: 10px;\n  margin: 1rem auto;\n  text-align: center;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n"], ["\n  background-color: #007f49;\n  padding: 10px;\n  margin: 1rem auto;\n  text-align: center;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n"])));
var PaymentText = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: white;\n  font-size: 1.2rem;\n  font-weight: 500;\n  margin: 0 !important;\n"], ["\n  color: white;\n  font-size: 1.2rem;\n  font-weight: 500;\n  margin: 0 !important;\n"])));
var SagePayIframe = function (_a) {
    var sagePayLink = _a.sagePayLink, amount = _a.amount;
    var hasScrolled = useRef(false);
    useEffect(function () {
        if (!hasScrolled.current) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            hasScrolled.current = true;
        }
    }, []);
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaymentBox, null,
                React.createElement(PaymentText, null,
                    "Your total payment is \u00A3",
                    amount.toFixed(2))),
            React.createElement(Root, null,
                React.createElement(Iframe, { url: sagePayLink, width: "100%" })))));
};
export default SagePayIframe;
var templateObject_1, templateObject_2, templateObject_3;
